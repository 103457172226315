import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { UtilsService } from './services/utils.service';
import { NodeJSService } from './services/node-js.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private screenOrientation: ScreenOrientation,
    private utils: UtilsService,
    private nodeJS: NodeJSService
  ) {
    this.updateCacheVersion('1.0.66');

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      this.splashScreen.hide();
    });

   /* this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', function (event) {
          event.preventDefault();
          event.stopPropagation();
          console.log('hello');
        }, false);
      });    
    });*/
    
  }

  ngOnInit(): void {
      this.doOnOrientationChange();

      this.screenOrientation.onChange().subscribe(
      () => {
            this.doOnOrientationChange();
      }
   );
  }

  updateCacheVersion(cacheVersion) {
    var currentVersion = window.localStorage.getItem('cacheVersion');
   // alert('cache version ' + currentVersion)
    console.log('currentVersion ' + currentVersion + ' new version ' + cacheVersion);
    if (currentVersion !== cacheVersion) {
        console.log('reloading page...');
        window.localStorage.setItem('cacheVersion', cacheVersion);
        window.location.reload(true);
    }
  }

  doOnOrientationChange() {
    this.utils.isPortrait = this.portraitByBrowser();
    //this.utils.isLandscape = !this.utils.isPortrait;
    /*switch(window.orientation) {
      case -90: case 90:      
        this.utils.isPortrait = (false);
        alert(this.utils.isPortrait)
        break;
      default:
        this.utils.isPortrait = (true);
        alert(this.utils.isPortrait)
        break;
    }*/
  //  alert(this.utils.isPortrait)



    this.utils.showStandby = false;
    if (this.utils.showingStandBy) {
      setTimeout( () => {
       // console.log('aqui after');
      if (this.utils.isPortrait) {
       // console.log('aqui after2' + this.slidesPortrait);
        // if (this.slidesPortrait !== undefined) {
         // console.log('aqui after3');
           // this.slidesPortrait.slideTo(this.standbyActiveIndex, 0).then(() => {
             // console.log('on orientation change ' + this.screenPortrait.value);

               this.utils.showStandby = true;
           // });
        //}
      } else {
       // console.log('aqui after2 ' + this.slidesLandscape);
        //if (this.slidesLandscape !== undefined) {
          //console.log('aqui after4');
            //this.slidesLandscape.slideTo(this.standbyActiveIndex, 0).then(() => {
              //console.log('on orientation change ' + this.screenPortrait.value);

              this.utils.showStandby = true;
           //});
       // }
      }

      }, 1);
  }
  }

  portraitByBrowser() {
    if (this.ifSafari()) {
      switch (window.orientation) {
        case -90: case 90:
          return false;
          // alert(this.utils.isPortrait);
        default:
          return true;
      }
     } else {
        return screen.orientation.type.match(/\w+/)[0] == 'portrait';
    }
  }

  ifSafari() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  ionViewWillLeave() {
    console.log('disconnecting socket...');
    this.nodeJS.socketIO.disconnect();
  }

  initNodeServer() {
    console.log('init node server...');
    this.nodeJS.socketIO.connect();
  }
}
