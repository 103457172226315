import { Component, OnInit, HostListener, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import es from '@angular/common/locales/es';
import { ModalController, NavParams } from '@ionic/angular';
import { RestService } from '../services/rest.service';
import { SHOW_BOOKING, INTERFACE_PRODUCT_LIST_NO_PHOTO, SENDER } from '../constants';
import * as constants from '../constants';
import { StatisticsService } from '../services/statistics.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { WebrtcService } from '../services/webrtc.service';

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value)//.map(key => value[key]);
  }
}
@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  language: string = this.translateService.currentLang;
  filters: any;
  showParent = true;

  selectedPrice: any;
  close = false;

  requestDate = true;
  requestTime = true;
  requestAttendees = true;
  requestBookingName = true;
  requestComments = true;
  arrayPrices = [];

  lastSubmodeSelected;
  lastChildModeSelected;

  @HostListener('touchend', ['$event'])
  onEnd(event) {
    this.rest.restartStandByTime();
  }

  constructor(
    private modalController: ModalController,
    private navParams: NavParams, public rest: RestService,
    private stats: StatisticsService, public translateService: TranslateService,
    public changeRef: ChangeDetectorRef, public decimalPipe: DecimalPipe,
    private webrtcService: WebrtcService) { }

  ngOnInit() {
    if (this.rest.response != undefined) {
      this.filters = this.rest.response.filters_without;
    }
    console.table(this.navParams);
    // this.alertType = this.navParams.data.alertType;

    // console.log('alert type ' + this.alertType);
    document.documentElement.style.setProperty('--item-background-color', this.rest.colorbackgroundItems);

  }

  priceIsEmpty(indexPrice) {
    return this.rest.currentProduct.price_translations.values[indexPrice] == '';
    // false;
  }

  getNamePrice(indexPrice: number) {
    let name_price = this.rest.currentProduct.price_translations.keys != undefined &&
      this.rest.currentProduct.price_translations.keys[this.rest.usedLang] != undefined ?
      this.rest.currentProduct.price_translations.keys[this.rest.usedLang][indexPrice] : '';

    if (name_price != undefined) {
      name_price = name_price.toUpperCase() + ' | ' +
        this.decimalPipe.transform(this.rest.currentProduct.price_translations.values[indexPrice], '1.2-2', 'es') + this.rest.getCurrency();
    }
    return name_price;
  }

  getTranslateMode(childIndex) {
    return this.rest.currentProduct.modes[this.rest.indexMode].submodes[childIndex].name_translations[this.rest.usedLang] != undefined ?
      this.rest.currentProduct.modes[this.rest.indexMode].submodes[childIndex].name_translations[this.rest.usedLang].toUpperCase() : ''
  }

  onFilterChecked(checkbox: any, filter: any) {
    if (checkbox.checked) {
      console.log('filterid ' + filter.id + ' filter ')
      this.rest.tempSelectedFilters.set(filter.id, filter);
    } else {
      this.rest.tempSelectedFilters.delete(filter.id);
    }
  }

  /*containsFilter(filter) {
    for (let index = 0; index < this.rest.tempSelectedFilters.size; index++) {
      const filter = this.rest.tempSelectedFilters[index];
      console.log('compare filter ' + this.rest.tempSelectedFilters[index].id + ' compare ' + filter.id)
      if (this.rest.tempSelectedFilters[index].id == filter.id) {
          console.log('contains filter...');
          return true;
      }
    }
    return false;
  }*/

  onClickMode(event: any, parentMode: any, subMode: any, childIndex) {
    // console.log(' checked ' + !event.target.checked );
    // this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = true;
    let nowChecked = !event.target.checked;

    if (nowChecked) {
      console.log('on checked, future selected ' + (this.getSelectedModesInParent() + 1) +
        ' minor max ' + (this.getSelectedModesInParent() + 1 <= parentMode.max));

      /*if (this.rest.selectedModes.length + 1 <= parentMode.max) {
        console.log('on checked MINOR ');
        this.rest.selectedModes.push( subMode );
        this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;
      } else {
        this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = true;
      }*/

      if (this.getSelectedModesInParent() + 1 <= parentMode.max) {
        console.log('debo añadir');
        this.rest.selectedModes.push(subMode);
        this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;

      } else {
        console.log('1 debo borrar')
        // this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = true;
        this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === this.lastSubmodeSelected), 1);
        this.rest.checksModes[this.rest.indexMode][this.lastChildModeSelected].isChecked = false;

        this.rest.selectedModes.push(subMode);
        this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;

        // this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === subMode), 1);
        // this.rest.selectedModes.push( subMode );
        //  this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;
      }



    } else {
      console.log('on no checked, future selected ' + (this.getSelectedModesInParent() - 1) +
        ' minor min ' + (this.getSelectedModesInParent() - 1 >= parentMode.min));

      if (this.getSelectedModesInParent() - 1 >= parentMode.min) {
        console.log('2 debo borrar');
        // this.rest.selectedModes.push( subMode );
        // this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;

      } else {
        console.log('no debo borrar');
        this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === subMode), 1);
        this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;


      }

      /* if (this.rest.selectedModes.length - 1 >= parentMode.min) {
         console.log('aqui entre ')
         this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === subMode), 1);
         this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = true;
       } else {
         this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;
       }*/

    }

    this.lastSubmodeSelected = subMode;
    this.lastChildModeSelected = childIndex;


    /* console.log('max select ' + JSON.stringify(parentMode.max) + ' total ' + parentMode.submodes.length + ' selected ' +
     this.rest.selectedModes.length);*/

    // this.changeRef.detectChanges();

    // console.log('after  ' + this.rest.checksModes[this.rest.indexMode][childIndex].isChecked);

  }

  onModeChecked(event: any, parentMode: any, subMode: any, childIndex) {
    // this.rest.checksModes[this.rest.indexMode][childIndex].isChecked = false;

    if (event.target.checked) {
      this.rest.selectedModes.push(subMode);
    } else {
      this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === subMode), 1);
    }

    console.log('on check box ' + JSON.stringify(event.target.checked) + ' selected ' + this.getSelectedModesInParent()
      + ' lastchild ' + this.lastChildModeSelected);


    // console.log('max select ' + JSON.stringify(parentMode.max) + ' total ' + parentMode.submodes.length + ' selected ' +
    // this.rest.selectedModes.length);

    if (this.getSelectedModesInParent() <= parentMode.max) {

    } else {
      // this.rest.presentToastType(' modos máximo ', 'warning');
      this.rest.selectedModes.splice(this.rest.selectedFilters.findIndex(x => x === this.lastSubmodeSelected), 1);
      this.rest.checksModes[this.rest.indexMode][this.lastChildModeSelected].isChecked = false;
      console.log('pongo a no  ' + ' lastChild ' + this.lastChildModeSelected + ' checks ' + JSON.stringify(this.rest.checksModes));
    }

    this.lastSubmodeSelected = subMode;
    this.lastChildModeSelected = childIndex;
    // console.log('on mode checked ' + JSON.stringify(this.rest.selectedModes))

  }

  onParentClick(parentIndex: number) {
    for (let i = 0; i < this.rest.response.filters_without.length; i++) {
      for (let e = 0; e < this.rest.response.filters_without[i].filter_elements.length; e++) {
        this.rest.checksFilters[i][e] = { showParent: !this.rest.checksFilters[i][e].showParent, isChecked: false };
      }
    }
    this.rest.parentsFilters[parentIndex] = !this.rest.parentsFilters[parentIndex];
  }

  onObservationSelected(event) {
    console.log('data ' + JSON.stringify(event.detail.value))
  }

  aceptFilters() {
    this.rest.selectedFiltersString = '';
    this.rest.selectedFilters = new Array();

    let i = 0;
    this.rest.tempSelectedFilters.forEach((value: any, key: any) => {
      console.log('compruebo key ' + key + ' value ' + value);
      this.rest.selectedFilters.push(value);
      this.rest.selectedFiltersString += (i === 0 ? '' : ' | ') + value.name_translations[this.rest.usedLang];
      i++;
    });
    this.rest.closeModal();
  }

  clearFilters() {
    this.rest.selectedFilters = new Array();
    this.rest.selectedFiltersString = '';
    this.rest.tempSelectedFilters.clear();
    for (let i = 0; i < this.rest.response.filters_without.length; i++) {
      for (let e = 0; e < this.rest.response.filters_without[i].filter_elements.length; e++) {
        this.rest.checksFilters[i][e] = { showParent: true, isChecked: false };
      }
    }
    this.rest.closeModal();
  }

  onBillClick(billType: string) {
    if (billType === 'cash') {
      if (this.rest.source !== 'dom' && this.rest.platform.is('android')) {
        (<any>window).UtilsAndroid.printTicket({
          message: 'Ha pedido la cuenta en efectivo',
          ip: this.rest.getPrinter().ip, port: this.rest.getPrinter().port
        },
          this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));
      }
      this.rest.closeModal();
    } else if (billType === 'card') {
      if (this.rest.source !== 'dom' && this.rest.platform.is('android')) {
        (<any>window).UtilsAndroid.printTicket({
          message: 'Ha pedido la cuenta en efectivo',
          ip: this.rest.getPrinter().ip, port: this.rest.getPrinter().port
        },
          this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));

      }
      this.rest.closeModal();
    }
  }

  getLocationType() {
    return this.rest.response.configuration.id_menu_app_type == constants.TYPE_HOTEL ?
      this.translateService.instant('modal.n_room') : this.translateService.instant('modal.n_table');
  }

  getLocation() {
    return this.rest.locationTemp != '' ? this.rest.locationTemp : this.rest.location;
  }

  onClickBtn1() { // CANCEL
    console.log('on click btn 1 ' + this.rest.alertType);
    this.rest.indexMode = 0;

    if (this.rest.alertType === 'message-type') {
      this.rest.closeModal();
    } else if (this.rest.alertType === 'observations') {
      this.rest.closeModal();
      this.rest.observationMsg = '';
      this.rest.locationTemp = '';
    } else if (this.rest.alertType === 'users-voip') {
      this.rest.closeModal();
    } else if (this.rest.alertType === 'filters') {
      this.clearFilters();
    } else if (this.rest.alertType === 'addProduct') {
      // console.log('on click btn 1 modes' );
      this.rest.countProduct = 1;
      this.rest.closeModal();
    } else if (this.rest.alertType === 'modes') {
      // console.log('on click btn 1 modes' );
      this.rest.closeModal();
    } else if (this.rest.alertType === 'prices') {
      // console.log('on click btn 1 prices');
      this.rest.closeModal();
    } else if (this.rest.alertType === 'booking') {
      // console.log('on click btn 1 prices');
      this.rest.closeModal();
    } else if (this.rest.alertType === 'input') {
      // console.log('on click btn 1 input');
      if (this.rest.modalAction === 'location') {
        this.rest.updateLocationAndSendMessageType(false);
      } else if (this.rest.modalAction === 'location_to_msg_type') {
        this.rest.closeModal();
      }else{
        this.rest.closeModal();
      }
    } else if (this.rest.alertType === 'bill') {
      console.log('on click btn 1 input');
      // this.rest.checkPassword(this.password);

      this.rest.closeModal();
    } else if (this.rest.alertType === 'confirm') {
      this.rest.closeModal();
    } else if (this.rest.alertType === 'privacy') {   
      console.log('soy privacy') 
      this.rest.closeModal();
    }else if (this.rest.alertType === 'password') {   
      this.rest.closeModal();
    }
  }



  onSentEmail(result) {
    console.log('email enviado ' + JSON.stringify(result['code']));
    if (result['code'] == 200) {
      this.rest.presentToastType(this.rest.translateJSON('modal.message_type_response_ok'), 'success');
      this.rest.observationSelected = '0';
      this.rest.observationMsg = '';
      this.rest.closeModal();
    } else {
      this.rest.presentToastType(result['message'], 'danger');
    }
    return false;
  }

  btnDisabled(){
    if(this.rest.alertType === 'privacy'){
      let result = this.rest.privacyAccept && this.rest.covidAccept;
      console.log('privacy result ' + result);

      return !result;
    }else{
      return false;
    }
  }

  async onClickBtn3() { // ACCEPT
    console.log('on click btn 3 ' + this.rest.alertType);

    if (this.rest.alertType === 'observations') {
      console.log('on send observations');

      await this.rest.sendObservation(this.rest.observationOptions[this.rest.observationSelected], this.rest.observationMsg,
        this.rest.locationTemp).then(result => this.onSentEmail(result),
          err => this.rest.log(this.rest.TAG, 'error ' + err)
        );

    } else if (this.rest.alertType === 'addProduct') {
      console.log('prices ' + this.getRealPricesNotEmpty() + ' modes ' + this.rest.currentProduct.modes.length);
      if (this.getRealPricesNotEmpty() > 1) {
        this.rest.showPrices();
      } else if (this.rest.currentProduct.modes.length > 0) {
        this.rest.showModes();
      } else {
        this.rest.prepareDataProduct(true, this.rest.date, this.rest.time);
        this.stats.addStat(constants.ACTION_ADD_PRODUCT, this.rest.currentProduct.level_number,
          this.rest.currentProduct.id, this.rest.countProduct);
        this.stats.postStatsToCMS();
        this.rest.closeModal();
      }
    } else if (this.rest.alertType === 'filters') {
      this.aceptFilters();
    } else if (this.rest.alertType === 'modes') {
      if (this.checkSelectedModes()) {
        if (this.rest.indexMode === this.rest.currentProduct.modes.length - 1) {
          this.rest.prepareDataProduct(true, this.rest.date, this.rest.time);
          this.stats.addStat(constants.ACTION_ADD_PRODUCT, this.rest.currentProduct.level_number,
            this.rest.currentProduct.id, this.rest.countProduct);

          this.stats.postStatsToCMS();
          this.rest.closeModal();

        } else if (this.rest.indexMode < this.rest.currentProduct.modes.length - 1) {
          this.rest.indexMode++;

          let nameMode = this.rest.currentProduct.modes[this.rest.indexMode].name_translations[this.rest.usedLang];
          nameMode = nameMode != undefined ? nameMode.toUpperCase() : nameMode;

          this.rest.titleModal = this.translateService.instant('choose') + ': ' + nameMode;

          if (this.rest.indexMode === this.rest.currentProduct.modes.length - 1) {
            this.rest.btnModal3 = { show: true, text: this.translateService.instant('accept') };
          }
        }
      } else {
        let errorMessage = this.translateService.instant('choose');

        if (this.rest.currentProduct.modes[this.rest.indexMode].min > 0) {
          errorMessage += ' ' + this.rest.currentProduct.modes[this.rest.indexMode].min + ' ' +
            this.translate('modal.mode') + ' ' + this.translate('modal.minimum') + '.';
        }

        if (this.rest.currentProduct.modes[this.rest.indexMode].max > 0) {
          errorMessage += ' ' + this.rest.currentProduct.modes[this.rest.indexMode].max + ' ' +
            this.translate('modal.mode') + ' ' + this.translate('modal.maximum');
        }

        this.rest.presentToastType(errorMessage, 'danger');
      }

    } else if (this.rest.alertType === 'prices') {
      console.log('on enter modes ' + this.rest.indexMode);
      if (this.rest.currentProduct.modes.length > 0) {
        this.rest.showModes();
      } else {
        this.rest.prepareDataProduct(true, this.rest.date, this.rest.time);
        this.stats.addStat(constants.ACTION_ADD_PRODUCT, this.rest.currentProduct.level_number,
          this.rest.currentProduct.id, this.rest.countProduct);
        this.stats.postStatsToCMS();
      }
    } else if (this.rest.alertType === 'booking') {

      let location = this.rest.locationTemp != '' ? this.rest.locationTemp : this.rest.location;

      console.log('location is ' + location);


      if (location != '') {
        if (this.rest.nodeJS.socketIO == undefined) {
          this.rest.nodeJS.initServer();

          setTimeout(() => {
            if (!this.rest.nodeJS.socketIO.connected) {
              this.rest.presentToastType(this.translate('order_no_placed'), 'danger');
              this.rest.closeModal();
            }
          }, 3000);

          this.rest.nodeJS.socketIO.on('connect', () => {
            console.log('on node connected ' + this.rest.nodeJS.socketIO.id);
            this.rest.nodeJS.socketIO.emit('save_socket_user', this.rest.nodeJS.socketIO.id, this.rest.contentID,
              location, SENDER);

            this.setupReadsAndSendOrder();
          });

          this.rest.nodeJS.socketIO.on('disconnect', () => {
            this.rest.nodeJS.socketIO = undefined;
            console.log('on node disconnected ');
          });
        } else {
          if (this.rest.nodeJS.socketIO != undefined && this.rest.nodeJS.socketIO.connected) {
            this.rest.nodeJS.socketIO.emit('save_socket_user', this.rest.nodeJS.socketIO.id, this.rest.contentID,
              location, SENDER);
            this.setupReadsAndSendOrder();
          } else {
            this.rest.presentToastType(this.translate('order_no_placed'), 'danger');
            this.rest.closeModal();
          }
        }

        // this.rest.closeModal();
      } else {
        this.rest.presentToastType('Introduzca un número de habitación', 'danger');
      }
    } else if (this.rest.alertType === 'bill') {
      this.rest.closeModal();
    } else if (this.rest.alertType === 'confirm') {
      console.log('on confirmmmmm ' + this.rest.modalAction);
      if (this.rest.modalAction === 'waiter') {
        console.log('on call waiter');
        if (this.rest.isAndroid()) {
          (<any>window).UtilsAndroid.printTicket({ message: 'Ha llamado al camarero', ip: '192.168.1.112', port: '9100' },
            this.rest.onPrinTicketSuccess.bind(this), this.rest.onPrinTicketFailure.bind(this));
        }
      } else if (this.rest.modalAction === 'deleteProduct') {
        this.rest.deleteProduct(this.rest.productToDelete);
        this.rest.productToDelete = '';
      } else if (this.rest.modalAction === 'empty_cart') {
        this.rest.cartProducts = [];
        this.rest.sendingOrder = false;
      }
      this.rest.closeModal();
    } else if (this.rest.alertType === 'language') {
      this.rest.closeModal();
    } else if (this.rest.alertType === 'input') {
      if (this.rest.modalAction === 'location_to_msg_type') {
        this.rest.updateLocationAndSendMessageType(true);
      }
    } else if (this.rest.alertType === 'observations') {
      console.log('observations ' + this.rest.observationMsg);
      this.rest.updateLocationAndSendObservation(true);
    } else if (this.rest.alertType === 'password') {
      this.rest.checkPassword(this.rest.textInput);
    }
  }

  setupReadsAndSendOrder() {
    this.rest.readPing();
    this.rest.readNewMessages();
    this.rest.readUpdateStates();
    this.rest.readUpdateStock();
    this.rest.setOrder();
  }

  translate(text: string) {
    return this.translateService.instant(text);
  }

  checkSelectedModes() {
    let selectedModesInParent = this.getSelectedModesInParent();

    return selectedModesInParent >= this.rest.currentProduct.modes[this.rest.indexMode].min &&
      selectedModesInParent <= this.rest.currentProduct.modes[this.rest.indexMode].max;
  }

  getSelectedModesInParent() {
    // console.log('checksmodes ' + JSON.stringify(this.rest.checksModes[this.rest.indexMode]));

    let selected = 0;
    for (let index = 0; index < this.rest.checksModes[this.rest.indexMode].length; index++) {
      const mode = this.rest.checksModes[this.rest.indexMode][index];
      // console.log('is checked ' + mode.isChecked);
      if (mode.isChecked) {
        selected++;
      }
    }
    return selected;
  }

  getRealPricesNotEmpty() {
    let valuesNotEmpty = 0;
    this.rest.currentProduct.price_translations.values.forEach(value => {
      if (value != '') {
        valuesNotEmpty++;
      }
    });
    return valuesNotEmpty;
  }

  getWriteComment() {
    return 'hola';
  }

  onTextChange() {
    this.rest.restartStandByTime();
  }

  addAttendees() {
    this.rest.attendeesAmount++;
  }

  subtractAttendees() {
    if (this.rest.attendeesAmount > 1) {
      this.rest.attendeesAmount--;
    }
  }
}
